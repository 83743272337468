import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import Layout from 'components/Layout'
import SectionsWrapper from 'components/SectionsWrapper'
import { useLayoutContext } from 'context/LayoutContext';
import SmallPrint from 'components/SmallPrint'
import Section from 'components/Section'
import Headline from 'components/Headline'
import SEO from 'components/SEO'
import externalLinks from 'utils/externalLinks';

const TaxCenterWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;
  padding-bottom: 2rem;
`;

const HeadlineSection = styled(Section)`
  ${Headline} {
    padding-bottom: 1rem;
    text-align: left;
  }
`

const StyledDiv = styled.div`
  padding-bottom: 2rem;

  &:last-child {
    @media (max-width: ${prop('theme.breakpoints.md')}) {
      padding-bottom: 0;
    }
  }

  ${SmallPrint} {
    font-style: normal;
    margin-bottom: 0;
  }
`;

function Rule606() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='Rule 606 Routing Reports' />
      <TaxCenterWrapper noGutters>
        <HeadlineSection withPadding>
          <Headline>
            Rule 606 Routing Reports
          </Headline>
          <hr />
        </HeadlineSection>
        <Section withPadding>
          <StyledDiv>
            <SmallPrint dark>
              Pursuant to SEC Rule 606, all broker-dealers are required by the
              Securities and Exchange Commission (“SEC”) and Financial Industry
              Regulatory Authority (“FINRA”) to inform their customers of payment
              for order flow practices.
            </SmallPrint>
          </StyledDiv>
          <StyledDiv>
            <SmallPrint dark>
              tastytrade directs its customers’ orders to an electronic order
              router that determines where the order should be routed to obtain
              favorable execution. tastytrade may also use discretion in selecting
              a particular broker-dealer or market participant in which to route
              customer orders, and may participate in payment for order flow
              programs that result in tastytrade receiving compensation for
              directing such orders. The source and nature of such compensation
              received will be furnished upon written request.
            </SmallPrint>
          </StyledDiv>
          <StyledDiv>
            <SmallPrint dark>
              tastytrade is committed to upholding the best execution for its
              customers. View our most recent 606 Report below for detailed
              information on the venues to which customer orders are routed.
            </SmallPrint>
          </StyledDiv>
        </Section>
      </TaxCenterWrapper>
    </Layout>
  )
}

export default Rule606;
